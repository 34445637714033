import React from 'react';
import './course.css';
import { Feature } from '../../components';

const text_color_hex = "#dddddd";

const Course = () => {
	return (
		<div className="eleviq__course section__padding">
			<div className="eleviq__course-feature">
			</div>
			<div className="eleviq__course-heading">
				<h1 className="gradient-text">Our testimonials</h1>
			</div>
			<div className="eleviq__course-container">
				<Feature title="Susan J." text="I wanted help with my SAT math and the tutor was so helpful and accommodating. I went from a score in the 60th percentile to 90th percentile within a month! Thank you so much!" text_color = {text_color_hex}/>
				<Feature title="Valeria G." text="My daughter was really struggling with her math class. We tried previous tutors but it was always a hassle to schedule, and we didn't see results for a long time. Eleviq is just so much better." text_color={text_color_hex}/>
				<Feature title="Greg G." text="I wasn't understanding my computer systems class until I joined Eleviq. They were so easy to use and schedule." text_color={text_color_hex}/>
			</div>
		</div>
		)
}

export default Course