import React from 'react';
import './hero.css'; // Assume you have some styles defined here
import heroImage from '../../assets/gpt.png';

const Hero = () => {
    return (
		<div className="eleviq__gpt section__padding" id="ChatGPT">
			<div className="eleviq__gpt-image">
				<img src = {heroImage}/>
			</div>
			<div className = "eleviq__gpt-content">
				<h1 className="gradient-text">The Most Time-Saving STEM Tutoring Platform.</h1>
				<p>We are a one-on-one online tutoring platform. We customize each learning plan so you can learn effectively and get the results you want fast. Schedule a session easily with one of our tutors from top 20 universities.</p>
				<h4><a href = "#mail">Click here for a 50% off coupon for your first two sessions</a></h4>
			</div>
		</div>
		)
};

export default Hero;
