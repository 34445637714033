import logo from './assets/logo-color.png';
import React from 'react';
import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Brand, CTA, Feature, Navbar, PaymentForm, ProductList } from './components';
import { Footer, Header, Course, GPT, Features, Learn } from './containers';
import { Home, Courses, FAQ, Store } from './pages';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


const stripePromise = loadStripe('pk_live_51NXSdMJEUdyyId1SCiHswkErOKqOr8m4uSUYevBhZlWWkoj3QH63Fo9pjmjLn5ofF1hBLZNSRSF4wXnJTznewEQq00VCTLIIvA');

function App() {
  return (
    <Elements stripe={stripePromise}>
      <div className="App">
        <div className="bg">
          <Router>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/courses' element={<Courses />} />
              <Route path='/FAQ' element={<FAQ />} />
              <Route path='/store' element={<Store />}/>
            </Routes>
          </Router>
        </div>
      </div>
    </Elements>
  );
}

export default App;

