import React from 'react';
import Feature from '../../components/feature/feature';
import './features.css';

const text_color_hex = "#333333";

const featuresData = [
  {
    title: 'All Math Levels',
    text: "We teach Elementary School Math, Middle School Math, SAT/ACT Math, Algebra I, Algebra II, Geometry, Precalculus, Calculus, Multivariable Calculus, GRE Quantitative, Linear Algebra, and Probability.",
	text_color: text_color_hex
  },
  {
    title: 'All Physics Levels',
    text: 'Our physics subjects cover Middle School Science, High School Physics, all AP Physics subjects, SAT/ACT Physics, College Intro Physics, Waves, Thermodynamics, Modern Physics, and Electronics.',
	text_color: text_color_hex
  },
  {
    title: 'All Computer Science Levels',
    text: "We cover a wide range of computer science subjects, including High School Computer Science, all AP Computer Science Subjects, Python, C, C++, Java, JavaScript, Data Structures, Algorithms, Microarchitecture, Systems, and Networking.",
	text_color: text_color_hex
  },
];

const Features = () => (
  <div className="eleviq__features section__padding" id="course">
    <div className="eleviq__features-heading">
      <h1 className="gradient__text">What we offer</h1>
      <p><b>We create custom learning plans and teaching styles for each of our subjects. All of our sessions are one-on-one.</b></p>
    </div>
    <div className="eleviq__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} text_color={item.text_color}/>
      ))}
    </div>
  </div>
);

export default Features;